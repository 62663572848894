<!--指标库设置  -->
<template>
	<div>
		<div class="child-top">
			<div class="input-from">
				<el-select placeholder="请选择设备类型" v-model="deviceUuid" @change="deviceChange" style="width: 130px;">
					<el-option v-for="(item, index) in deviceList" :label="item.name" :value="item.uuid" :key="'staff' + index"></el-option>
				</el-select>

				<!-- <el-date-picker v-model="dateTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="getData"> </el-date-picker> -->

				<el-date-picker v-model="dateTime" @change="getData" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
				<el-cascader
					placeholder="请选择运动队"
					v-model="deparmentId"
					:options="deparmentList"
					collapse-tags
					:show-all-levels="false"
					@change="getStaffByStationList"
					:props="{ multiple: true, children: 'children', label: 'name', value: 'uuid', emitPath: false }"
				></el-cascader>
				<el-select clearable placeholder="请选择运动员" v-model="stationUuid" @change="getData" style="width: 130px;">
					<el-option v-for="(item, index) in stationList" :label="item.name" :value="item.uuid" :key="'staff' + index"></el-option>
				</el-select>

				<el-input
					@keyup.enter.native="getData(true)"
					style="width: 150px;"
					class="left-child-input left-child-input-content"
					clearable
					suffix-icon="iconfont iconsousuo"
					placeholder="请输入设备名称"
					v-model="input4"
				>
				</el-input>
				<el-button @click="getData(true)" slot="append" class="searchBtn" round>搜索</el-button>
			</div>
			<div class="operation-button">
				<!-- <el-button class="brotherBtns importFile room" style="border-right:0;">
					<div class="fileInp">
						<input @input="$importFile($event, 'physEquipment', device_uuid)" type="file" />
					</div>
					<i class="iconfont icon-daoru-icon" style="color:#F5A623;"></i>
					<span>导入</span>
				</el-button> -->

				<el-button @click="importFile" class="importFile" round style="background:rgb(53, 200, 203);">
					<span>导入</span>
				</el-button>

				<el-button @click="updateData" class="new_btn" round v-if="server_url !== 'dps.kxunpt.cn'">
					<span>更新</span>
				</el-button>
			</div>
		</div>

		<div class="table_view" style="background: #fff">
			<el-table v-loading="tableLoading" class="content-test-table" :data="tableList" style="width: 100%" :key="'table1'">
				<el-table-column label="序号" prop="order_no" show-overflow-tooltip type="index" width="60" align="center">
					<template slot-scope="scope">{{ (page - 1) * 10 + scope.$index + 1 }}</template>
				</el-table-column>
				<el-table-column label="运动队" prop="department_name" show-overflow-tooltip align="center"></el-table-column>
				<el-table-column label="运动员" prop="staff_name" show-overflow-tooltip align="center"></el-table-column>

				<el-table-column label="设备名称" prop="equipment_name" show-overflow-tooltip align="center"> </el-table-column>
				<el-table-column label="测试/训练动作名称" prop="action_name" show-overflow-tooltip align="center"></el-table-column>
				<el-table-column label="测试时间" prop="train_time" show-overflow-tooltip align="center"> </el-table-column>
				<el-table-column label="操作" width="120">
					<template slot-scope="scope">
						<!-- <el-button circle icon="el-icon-s-data" type="success" @click="lookDetail(scope.row)"></el-button> -->
						<el-button class="yellowColor" size="medium" type="text" @click="lookDetail(scope.row)">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				:current-page.sync="page"
				:page-size="page_size"
				:total="total"
				@current-change="handleCurrentChange"
				class="table-pagination"
				layout="total, jumper,  ->, prev, pager, next"
			></el-pagination>
		</div>

		<el-dialog title="Gymaware" center :visible.sync="gymDialogVisible" width="70%" class="gymaware_view" :show-close="false" :close-on-click-modal="false">
			<div class="echarts_view">
				<div class="echarts_content echarts_content_top">
					<div class="echarts_title">平均功率(W)</div>
					<div id="GymEchartsLine"></div>
				</div>
				<div class="echarts_content">
					<div class="echarts_title">平均速度(m/s)</div>
					<div id="GymEchartsBar"></div>
				</div>
			</div>
			<el-table v-loading="tableLoading" class="content-test-table" :data="GymwareList" style="width: 100%" @selection-change="selectChange" :key="'table2'">
				<el-table-column label="时间" show-overflow-tooltip align="center" width="80">
					<template slot-scope="scope">{{ getTime(scope.row.recorded) }}</template>
				</el-table-column>
				<el-table-column label="组数" show-overflow-tooltip type="index" align="center" width="80">
					<template slot-scope="scope">{{ scope.$index + 1 }}</template>
				</el-table-column>
				<el-table-column label="次数" show-overflow-tooltip align="center" width="80">
					<template slot-scope="scope">{{ scope.row.repCount }}</template>
				</el-table-column>
				<el-table-column label="动作名称" show-overflow-tooltip width="240" align="center">
					<template slot-scope="scope">{{ scope.row.exerciseName }}</template>
				</el-table-column>
				<el-table-column label="负重(kg)" show-overflow-tooltip align="center" width="80">
					<template slot-scope="scope">{{ scope.row.barWeight }}</template>
				</el-table-column>
				<el-table-column label="平均速度 (m/s)" shom-overflow-tooltip align="center">
					<template slot-scope="scope">{{ scope.row.average_v }}</template>
				</el-table-column>
				<el-table-column label="最佳平均速度 (m/s)" show-overflow-tooltip align="center">
					<template slot-scope="scope">{{ scope.row.best_v }}</template>
				</el-table-column>
				<el-table-column label="平均功率 (W)" show-overflow-tooltip align="center">
					<template slot-scope="scope">{{ scope.row.average_p }}</template>
				</el-table-column>
				<el-table-column label="最佳平均功率 (W)" show-overflow-tooltip align="center">
					<template slot-scope="scope">{{ scope.row.best_p }}</template>
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
				<el-button @click="gymDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<!-- <el-dialog title="血乳酸" center :visible.sync="EKFDialogVisible" width="30%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="ekf_view">
				<span>{{ EKFDataObj.category }}</span>
				<p>{{ EKFDataObj.value }}</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="EKFDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog> -->

		<el-dialog :title="deviceName" center :visible.sync="EKFDialogVisible" width="30%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="ekf_view">
				<span>{{ EKFDataObj.category }}</span>
				<p>{{ EKFDataObj.value }}</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
				<el-button @click="EKFDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog :title="deviceName" center :visible.sync="CLTDialogVisible" width="50%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="clt_view">
				<p class="top_title"><span></span> 过程数据</p>
				<div id="cltLineEcharts"></div>
				<p class="top_title"><span></span>结果数据</p>
				<el-table class="content-test-table" :data="[cltData.table_data]" style="width: 100%" key="table3clt">
					<el-table-column label="峰值速度" prop="max_speed" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="峰值力量" prop="max_force" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均功率" prop="avg_power" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="峰值功率" prop="max_power" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均力量" prop="avg_force" show-overflow-tooltip align="center"> </el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="CLTDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog :title="deviceName" center :visible.sync="CXLQDialogVisible" width="70%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="clt_view">
				<div class="clt_view_select">
					<el-select v-model="ccFirstValue" placeholder="请选择" @change="ccSelectChange">
						<el-option v-for="item in ccFirstOptions" :key="item.id" :label="item.label" :value="item.id"> </el-option>
					</el-select>
					<el-select v-model="ccLastValue" placeholder="请选择" @change="ccSelectChange">
						<el-option v-for="item in ccLastOptions" :key="item.id" :label="item.label" :value="item.id"> </el-option>
					</el-select>
				</div>

				<p class="top_title"><span></span> 过程数据</p>
				<div id="cxlqLineEcharts"></div>

				<p class="top_title"><span></span>结果数据</p>

				<el-table class="content-test-table" :data="cxlqData.table_data" style="width: 100%" key="table2cxlq" height="200">
					<el-table-column label="距离(m)" prop="position" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="分段时长(s)" prop="segmentTime" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="总时长(s)" prop="totalTime" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="峰值速度(m/s)" prop="peakSpeed" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均速度(m/s)" prop="avgSpeed" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="峰值功率(W)" prop="peakPower" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均功率(W)" prop="avgPower" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="峰值力(N)" prop="peakForce" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均力(N)" prop="avgForce" show-overflow-tooltip align="center"> </el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="CXLQDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog :title="deviceName" center :visible.sync="WGJDialogVisible" width="50%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="clt_view">
				<div class="clt_view_select">
					<el-select v-model="wgjSelectValue" placeholder="请选择" @change="wgjSelectChange">
						<el-option v-for="item in wgjFirstOptions" :key="item.id" :label="item.label" :value="item.id"> </el-option>
					</el-select>
				</div>

				<p class="top_title"><span></span> 过程数据</p>
				<div id="wgjLineEcharts"></div>
				<p class="top_title"><span></span>结果数据</p>
				<el-table class="content-test-table" :data="[wgjData.table_data]" style="width: 100%" key="table3wgj">
					<el-table-column label="峰值功率" prop="max_power" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均功率" prop="avg_power" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="峰值速度" prop="max_speed" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均速度" prop="avg_speed" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="峰值RFD" prop="max_rfd" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均RFD" prop="avg_rfd" show-overflow-tooltip align="center"> </el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="WGJDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog title="导入" center :visible.sync="importDialogVisible" width="30%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="选择设备" prop="deviceUuid">
					<el-select placeholder="请选择设备" v-model="ruleForm.deviceUuid" style="width: 85%;">
						<el-option v-for="(item, index) in importList" :label="item.name" :value="item.uuid" :key="'import' + index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="文件" prop="import_file">
					<el-button class="brotherBtns importFile room">
						<div class="fileInp">
							<input @input="fileChange($event)" type="file" />
						</div>
						<i class="iconfont icon-daoru-icon" style="color:#F5A623;"></i>
						<span>选择文件</span>
					</el-button>
					<div>
						{{ ruleForm.import_file && ruleForm.import_file.name }}
					</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="saveImport">确 定</el-button>
				<el-button @click="cancelImport">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog :title="deviceName" center :visible.sync="dataDetailDialogVisible" width="50%" class="ekfDialog_view detailDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="clt_view">
				<p class="top_title"><span></span>数据</p>
				<el-form :model="dataDetailForm" ref="dataDetailForm" label-width="100px" class="demo-ruleForm">
					<div class="div-flex">
						<el-form-item label="姓名：" prop="username">{{ dataDetailForm.username }}</el-form-item>
						<el-form-item label="性别：" prop="sex">{{ dataDetailForm.sex }}</el-form-item>
					</div>
					<div class="div-flex">
						<el-form-item label="身高：" prop="hight">{{ dataDetailForm.hight }}</el-form-item>
						<el-form-item label="测试时间：" prop="record_time">{{ dataDetailForm.record_time }}</el-form-item>
					</div>
				</el-form>

				<p class="top_title" style="margin-top: 10px;"><span></span>测试数据</p>
				<el-table :data="dataDetailTable" style="width: 100%">
					<el-table-column prop="time_index" label="#" align="center"> </el-table-column>
					<el-table-column prop="touchdown" label="触地时间(秒)" align="center"> </el-table-column>
					<el-table-column prop="soar" label="腾空时间(秒)" align="center"> </el-table-column>
					<el-table-column prop="hight" label="高度(厘米)" align="center"> </el-table-column>
					<el-table-column prop="power" label="功率(W)" align="center"> </el-table-column>
					<el-table-column prop="pace" label="步速(秒)" align="center"> </el-table-column>
					<el-table-column prop="rsi" label="RSI(m/s)" align="center"> </el-table-column>
				</el-table>

				<p class="top_title" style="margin-top: 10px;"><span></span>腾空时间 (图表)</p>
				<div id="tksjEcharts"></div>

				<p class="top_title" style="margin-top: 10px;"><span></span>高度 (图表)</p>
				<div id="tydEcharts"></div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dataDetailDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog :title="deviceName" center :visible.sync="CMJDialogVisible" width="60%" class="ekfDialog_view clbDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="clt_view">
				<p class="top_title"><span></span> 基本信息</p>
				<div class="clt_info_view">
					<div><span>日期</span>: {{valDataObj.date}}</div>
					<div><span>动作名称</span>:	{{valDataObj.action_name}}</div>
					<div><span>次数</span>: {{valDataObj.count}}</div>
					<div><span>体重(KG)</span>: {{valDataObj.weight}}	</div>
				</div>
				<p class="top_title"><span></span> 过程数据</p>
				<div id="cmjLineEcharts"></div>
				<p class="top_title"><span></span>结果数据</p>
				<el-table class="content-test-table" :data="valDataObj.row_arr" style="width: 100%" key="table3CMJ">
					<el-table-column label="次数" prop="rows" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="跳跃高度（imp-mom）（厘米）" prop="JUMP_HEIGHT" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="腾空时间（毫秒）" prop="FLIGHT_TIME" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="落地缓冲时间（秒）" prop="BRAKING_PHASE_DURATION" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="起跳时间（毫秒）" prop="CONTRACTION_TIME" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="反应性力量指数RSI（米/秒）" prop="RSI_MODIFIED_IMP_MOM" show-overflow-tooltip align="center"> </el-table-column>
				</el-table>
				<p class="top_title zbsj"><span></span>指标数据</p>
				<div v-for="(item, index, idx) in valDataObj.norms_arr" :key="'clbZB=' + index" class="zb_echarts_view">
					<h2>指标{{idx + 1}}: {{item.name}}</h2>
					<div class="clt_info_view">
						<div><span>范围</span>: {{item.fanwei}}</div>
						<div><span>平均数</span>:	{{item.pingjunshu}}</div>
						<div><span>协方差</span>: {{item.xiefangcha}}</div>
						<div><span>标准差</span>: {{item.biaozhuncha}}	</div>
					</div>
					<div :id="index" class="clbEchartsView"></div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="CMJDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog :title="deviceName" center :visible.sync="zzDialogVisible" width="50%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="clt_view">
				<div class="clt_view_select">
					<el-select v-model="wgjSelectValue" placeholder="请选择" @change="zzSelectChange">
						<el-option v-for="item in wgjFirstOptions" :key="item.id" :label="item.label" :value="item.id"> </el-option>
					</el-select>
				</div>
				<p class="top_title"><span></span> 过程数据</p>
				<div id="zzLineEcharts"></div>
				<p class="top_title"><span></span>结果数据</p>
				<el-table class="content-test-table" :data="[zzDataObj.table_data]" style="width: 100%" key="table3wgj">
					<el-table-column label="峰值功率" prop="max_power" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均功率" prop="avg_power" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="峰值速度" prop="max_speed" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均速度" prop="avg_speed" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="峰值RFD" prop="max_rfd" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column label="平均RFD" prop="avg_rfd" show-overflow-tooltip align="center"> </el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="zzDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog :title="deviceName" center :visible.sync="VALDialogVisible" width="30%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="clb_view">
				<p><span> test</span>{{ valDataObj.test }}</p>
				<p><span>tags</span>{{ valDataObj.tags }}</p>
				<p><span>weight</span>{{ valDataObj.weight }}</p>
				<p><span>exercise_length</span>{{ valDataObj.exercise_length }}</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="VALDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog title="划船机" center :visible.sync="hcjDialogVisible" width="80%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="hcj_view">
				<div class="top_date_view">
					<div class="date_view">日期：{{hcjDataObj.date}}</div>
					<div class="date_view">运动员：{{hcjInfoObj.staff_name}}</div>
					<div class="date_view">测试/训练动作名称：{{hcjInfoObj.action_name}}</div>
				</div>
				<ul>
					<li>
						<span>所用时间</span>
						<p>{{hcjDataObj.elapsed_time}}</p>
					</li>
					<li>
						<span>消耗热量</br>(CAL)</span>
						<p>{{hcjDataObj.total_calories}}</p>
					</li>
					<li>
						<span>浆数</span>
						<p>{{hcjDataObj.stroke_count}}</p>
					</li>
					<li>
						<span>划速</br>(M/S)</span>
						<p>{{hcjDataObj.speed}}</p>
					</li>
					<li>
						<span>浆频</br>(S/M)</span>
						<p>{{hcjDataObj.stroke_rate}}</p>
					</li>
					<li>
						<span>划距</br>(M/S)</span>
						<p>{{hcjDataObj.stroke_distance}}</p>
					</li>
				</ul>

				<div class="hcj_echarts_view" v-for="(item, index) in hcjEchartsList" :key="'hcj_e_' + index">
					<p class="title">{{item.name}}</p>
					<div class="value_view">
						<div class="value_view_title">
							<div>平均</div>
							<div>最佳</div>
						</div>
						<div class="value_view_bot">
							<div>{{hcjEchartsObject[item.id].avg}}</div>
							<div>{{hcjEchartsObject[item.id].max}}</div>
						</div>
					</div>
					<div :id="item.id" class="hcj_echarts"></div>
				</div>
			</div>

			
			<span slot="footer" class="dialog-footer">
				<el-button @click="hcjDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog title="功率自行车" center :visible.sync="wattDialogVisible" width="80%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="watt_view clt_view">
				<p class="top_title"><span></span> 基本信息</p>
				<div class="date_view">日期：{{wattDataObj.date}}</div>
				<p class="top_title"><span></span> 结果数据</p>
				<div class="clt_info_view">
					<div><span>时间</span>: N/A</div>
					<div><span>距离（km）</span>:	N/A</div>
					<div><span>踏蹬效率得分 （平均值）</span>: N/A</div>
					<div><span>踏蹬效率平衡性</span>: N/A</div>
					<div><span>功率（平均值）（瓦）</span>: {{wattDataObj.power_avg}}</div>
					<div><span>功率（最大值）（瓦）</span>:	{{wattDataObj.power_max}}</div>
					<div><span>踏频（平均值)（转/分钟）</span>: {{wattDataObj.cadence_avg}}</div>
					<div><span>踏频（最大值)（转/分钟）</span>: {{wattDataObj.cadence_max}}</div>
					<div><span>心率（平均值）（次/分钟</span>: N/A</div>
					<div><span>心率（最大值）（次/分钟）</span>:	N/A</div>
					<div><span>速度（平均值）（千米/小时）	</span>: N/A</div>
					<div><span>速度（最大值）（千米/小时）</span>: N/A</div>
					<div><span>功率/公斤（瓦）</span>: N/A</div>
					<div><span>能量消耗（千卡）</span>: N/A</div>
				</div>
				<div id="wattEchartsLine"></div>
				<p class="top_title"><span></span> 踏蹬数据</p>
				<div class="bot_view">
					<dl>
						<dd>功率</dd>
						<dt>{{wattDataObj.power}}</dt>
					</dl>
					<dl>
						<dd>心率</dd>
						<dt>N/A</dt>
					</dl>
					<dl>
						<dd>踏频</dd>
						<dt>{{wattDataObj.cadence}}</dt>
					</dl>
					<dl>
						<dd>左侧峰值</dd>
						<dt>N/A</dt>
					</dl>
					<dl>
						<dd>右侧峰值</dd>
						<dt>N/A</dt>
					</dl>
					<dl>
						<dd>平衡性</dd>
						<dt>N/A</dt>
					</dl>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="wattDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog title="EliteForm" center :visible.sync="eliteDialogVisible" width="80%" class="ekfDialog_view" :show-close="false" :close-on-click-modal="false">
			<div class="elite_view">
				<div class="interTop_left">
					<!-- <el-avatar size="large" :src="'https://' + infoData.head_img"></el-avatar> -->
					<el-avatar :size="50">
						<img :src="eliteDataObj && eliteDataObj.staff_info.head_img ? 'http://' + eliteDataObj && eliteDataObj.staff_info.head_img : circleUrl" />
					</el-avatar>
					<div class="otim">
						姓名:
						<span>{{ eliteDataObj && eliteDataObj.staff_info.name }}</span>
						性别:
						<span>{{ eliteDataObj && eliteDataObj.staff_info.sex == 1 ? '男' : eliteDataObj && eliteDataObj.staff_info.sex == 2 ? '女' : '' }}</span>
						身高:
						<span>{{ eliteDataObj && eliteDataObj.staff_info.height }}cm</span>
						体重:
						<span>{{ eliteDataObj && eliteDataObj.staff_info.weight }}kg</span>
						项目:
						<span>{{ eliteDataObj && eliteDataObj.staff_info.sport_name }}</span>
						运动队:
						<span>{{ eliteDataObj && eliteDataObj.staff_info.department_name }}</span>
					</div>
				</div>
				<p class="top_title"><span></span> 设备信息</p>
				<ul>
					<li><span>设备类型：</span>{{eliteDataObj.equipment_info[0] && eliteDataObj.equipment_info[0].cate_name}}</li>
					<li><span>设备品牌：</span>{{eliteDataObj.equipment_info[0] && eliteDataObj.equipment_info[0].brand_name}}</li>
					<li><span>设备名称：</span>{{eliteDataObj.equipment_info[0] && eliteDataObj.equipment_info[0].equipment_name}}</li>
					<li><span>训练时间：</span>{{eliteDataObj.equipment_info && eliteDataObj.equipment_info.record_time}}</li>
				</ul>
				<p class="top_title"><span></span> 数据信息</p>
				<ul>
					<li><span>动作名称：</span>{{eliteDataObj.action_info && eliteDataObj.action_info[0]}}</li>
					<li><span>重量：</span>{{eliteDataObj && eliteDataObj.action_info['weight']}}</li>
				</ul>
				<el-tabs v-model="activeName" @tab-click="handleClick" type="card">
					<el-tab-pane label="速度" name="first"></el-tab-pane>
					<el-tab-pane label="功率" name="second"></el-tab-pane>
					<el-tab-pane label="做功" name="third"></el-tab-pane>
				</el-tabs>
				<div class="bot_view">
					<div class="bot_view_left" id="elite_echarts"></div>
					<div class="bot_view_right">
						<el-table border height="242" :data="activeName == 'first' ? eliteDataObj.table_info && eliteDataObj.table_info[0].test_data : activeName == 'second' ? eliteDataObj.table_info && eliteDataObj.table_info[1].test_data : activeName == 'third' ? eliteDataObj.table_info && eliteDataObj.table_info[2].test_data : []">
							 <el-table-column
									prop="num"
									label="序号"
									align="center">
								</el-table-column>
								<el-table-column
									prop="peak"
									align="center"
									:label="activeName == 'third' ? '做功' : '峰值'">
								</el-table-column>
								<el-table-column
									prop="average"
									label="平均值"
									align="center"
									v-if="activeName !== 'third'">
								</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="bot_info" v-if="activeName == 'first'">
					<div><span>峰值速度（米/秒）：</span>{{eliteDataObj.table_info && eliteDataObj.table_info[0].sum_data[0].value}}</div>
					<div><span>平均速度（米/秒）：</span>{{eliteDataObj.table_info && eliteDataObj.table_info[0].sum_data[1].value}}</div>
				</div>
				<div class="bot_info" v-if="activeName == 'second'">
					<div><span>峰值功率（瓦）：</span>{{eliteDataObj.table_info && eliteDataObj.table_info[1].sum_data[0].value}}</div>
					<div><span>平均功率（瓦）：</span>{{eliteDataObj.table_info && eliteDataObj.table_info[1].sum_data[1].value}}</div>
				</div>
				<div class="bot_info" v-if="activeName == 'third'">
					<div><span>做功峰值（焦耳）：</span>{{eliteDataObj.table_info && eliteDataObj.table_info[2].sum_data[0].value}}</div>
					<div><span>做功平均值（焦耳）：</span>{{eliteDataObj.table_info && eliteDataObj.table_info[2].sum_data[1].value}}</div>
					<div><span>总功（焦耳）：</span>{{eliteDataObj.table_info && eliteDataObj.table_info[2].sum_data[2].value}}</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="eliteDialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			server_url: '',
			tableLoading: false, //loading
			tableList: [],
			deviceSelect: 1,
			page_size: 10,
			total: 0,
			page: 1,
			searchDate: new Date(),
			personList: [],
			personUuid: '',
			GymwareList: [],
			lineData: [],
			barData: [],
			infoData: {
				head_img: '',
				name: '',
				sex: 1,
				height: 190,
				weight: 59,
				sport_name: '',
				department_name: ''
			},
			activeName: 'first',

			dateTime: [],
			deparmentList: [],
			deparmentId: [],
			stationList: [],
			stationUuid: [],
			deviceList: [],
			deviceUuid: '',
			deviceName: '',
			input4: '',
			gymDialogVisible: false,
			EKFDialogVisible: false,
			CLTDialogVisible: false,
			CXLQDialogVisible: false,
			WGJDialogVisible: false,
			CMJDialogVisible: false,
			zzDialogVisible: false,
			hcjDialogVisible: false,
			VALDialogVisible: false,
			wattDialogVisible: false,
			eliteDialogVisible: false,
			valDataObj: {},
			hcjDataObj: {},
			hcjInfoObj: {},
			wattDataObj: {},
			eliteDataObj: {
				staff_info: {},
				equipment_info: {},
				action_info: {}
			},

			importDialogVisible: false,
			ruleForm: {
				deviceUuid: '',
				import_file: null,
			},
			rules: {
				deviceUuid: { required: true, message: '请选择设备', trigger: 'change' },
				import_file: { required: true, message: '请选择文件', trigger: 'change' },
			},
			importList: [],

			dataDetailDialogVisible: false,
			dataDetailForm: {},
			dataDetailTable: [],

			EKFDataObj: {},

			ccFirstValue: '1',
			ccLastValue: '1',

			device_uuid: '',

			cxlqData: {
				table_data: [],
			},
			cxlqTableList: [],

			wgjData: {
				table_data: [],
			},
			zzDataObj: {
				table_data: [],
			},
			wgjSelectValue: '1',

			cltData: {
				table_data: [],
			},

			ccFirstOptions: [
				{
					label: '时间 m/s',
					id: '1',
				},
				{
					label: '位置 /m',
					id: '2',
				},
			],
			ccLastOptions: [
				{
					label: '功率 /W',
					id: '1',
				},
				{
					label: '速度 m/s',
					id: '2',
				},
				{
					label: '力量 /kg',
					id: '3',
				},
				{
					label: '加速度 m/s',
					id: '4',
				},
			],
			wgjFirstOptions: [
				{
					label: '峰值功率',
					id: '1',
				},
				{
					label: '平均功率',
					id: '2',
				},
				{
					label: '峰值速度',
					id: '3',
				},
				{
					label: '平均速度',
					id: '4',
				},
				{
					label: '峰值Rfd',
					id: '5',
				},
				{
					label: '平均Rfd',
					id: '6',
				},
			],
			hcjEchartsList: [
				{
					name: '浆频(S/M)',
					id: 'leftTopEcharts'
				},
				{
					name: '划距(CM)',
					id: 'rightTopEcharts'
				},{
					name: '拉浆时间',
					id: 'leftBotEcharts'
				},{
					name: '回浆时间',
					id: 'rightBotEcharts'
				},
			],
			hcjEchartsObject: {
				'leftTopEcharts': {},
				'rightTopEcharts': {},
				'leftBotEcharts': {},
				'rightBotEcharts': {}
			},
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
		}
	},
	mounted() {
		this.server_url = window.location.hostname
		// this.$axios.post('/p/Gymware/staffLists').then(res => {
		// 	if (res.data.code == 0) {
		// 		this.personUuid = res.data.data[0].athleteReference
		// 		this.personList = res.data.data
		// 	}
		// })

		this.$axios.post('/p/staff/tree').then(res => {
			this.deparmentList = res.data.data.department
		})

		this.$axios
			.post(
				'/p/equipment_hardware/cateListAll',
				this.$qs({
					type: 1,
				})
			)
			.then(res => {
				this.deviceList = res.data.data.rows
				this.deviceUuid = res.data.data.rows[0].uuid
				this.deviceName = res.data.data.rows[0].name
				this.getData()
			})

		this.$axios
			.post(
				'/p/equipment_hardware/listAll',
				this.$qs({
					type: 1,
					data_import: 1,
				})
			)
			.then(res => {
				this.importList = res.data.data.rows
			})
	},

	methods: {
		handleClick(tab, event) {
			console.log(tab, event);
			this.$echarts.init(document.getElementById('elite_echarts')).dispose()
			this.setEliteEcharts()
		},
		updateData() {
			this.$axios
				.post(
					'/p/equipment_record/phySync',
					this.$qs({
						equipment_uuid: this.deviceUuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.getData()
						this.$message({
							type: 'success',
							message: res.data.message,
						})
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
		},
		importFile() {
			this.importDialogVisible = true
		},
		fileChange(e) {
			this.ruleForm.import_file = e.target.files[0]
		},
		cancelImport() {
			this.$refs['ruleForm'].resetFields()
			this.importDialogVisible = false
			this.ruleForm = {
				deviceUuid: '',
				import_file: null,
			}
		},
		saveImport() {
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					let formData = new FormData()
					formData.append('uuid', this.ruleForm.deviceUuid)
					formData.append('file', this.ruleForm.import_file)
					this.$axios.post('/p/equipment_record/importData', formData).then(res => {
						if (res.data.code == 0) {
							// this.importDialogVisible = false
							this.$message({
								type: 'success',
								message: res.data.message,
							})
							this.getData()
							this.cancelImport()
						} else {
							this.$message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		lookDetail(rows) {
			this.$axios
				.post(
					'/p/equipment_record/phyRead',
					this.$qs({
						id: rows.id,
						type: rows.type,
					})
				)
				.then(res => {
					if (rows.type == 2) {
						this.gymDialogVisible = true
						if (!res.data.data) return false
						this.GymwareList = res.data.data.rows
						this.setGymEcharts(res.data.data)
					} else if (rows.type == 1) {
						this.EKFDialogVisible = true
						if (!res.data.data) return false
						this.EKFDataObj = res.data.data
					} else if (rows.type == 5) {
						this.CLTDialogVisible = true
						if (!res.data.data) return false
						this.cltData = res.data.data
						this.$nextTick(() => {
							this.setCltLineEcharts(res.data.data)
						})
					} else if (rows.type == 4) {
						this.CXLQDialogVisible = true
						if (!res.data.data) return false
						this.cxlqData = res.data.data
						this.$nextTick(() => {
							this.setCxlqLineEcharts(res.data.data.chart_data)
						})
					} else if (rows.type == 3) {
						this.WGJDialogVisible = true
						if (!res.data.data) return false
						this.wgjData = res.data.data
						this.$nextTick(() => {
							this.setWgjLineEcharts(res.data.data.chart_data)
						})
					} else if (rows.type == 10) {
						this.dataDetailDialogVisible = true
						if (!res.data.data) return false
						this.dataDetailForm = res.data.data
						this.dataDetailTable = res.data.data.detail
						this.$nextTick(() => {
							this.setTksjEcharts(res.data.data.soar_list)
							this.setTydEcharts(res.data.data.hight_list)
						})
					} else if (rows.type == 11) {
						this.valDataObj = res.data.data
						if(rows.action_name == 'CMJ') {
							this.CMJDialogVisible = true
							this.$nextTick(() => {
								this.setCMJLineEcharts(res.data.data.chart_data)
								for(var i in res.data.data.norms_arr) {
									this.setCMJBarEcharts(i, res.data.data.norms_arr[i])
								}
							})
						} else {
							this.VALDialogVisible = true
						}
					} else if (rows.type == 6) {
						this.zzDialogVisible = true
						this.zzDataObj = res.data.data
						this.$nextTick(() => {
							this.setzzLineEcharts(res.data.data.chart_data)
							
						})
					} else if(rows.type == 12) {
						this.hcjDialogVisible = true
						this.hcjInfoObj = rows
						this.hcjDataObj = res.data.data
						this.hcjEchartsObject['leftTopEcharts'] = res.data.data.stroke_rate_info
						this.hcjEchartsObject['rightTopEcharts'] = res.data.data.stroke_distance_info
						this.hcjEchartsObject['leftBotEcharts'] = res.data.data.drive_time_info
						this.hcjEchartsObject['rightBotEcharts'] = res.data.data.stroke_recovery_time_info
						this.$nextTick(() => {
							this.hcjEchartsList.forEach(item => {
								this.setHcjEcharts(item.id)
							})
						})
					} else if (rows.type == 13) {
						this.wattDialogVisible = true
						this.wattDataObj = res.data.data
						this.$nextTick(() => {
								this.setWattEchartsLine(res.data.data)
						})
					} else if (rows.type == 14) {
						this.eliteDialogVisible = true
						this.eliteDataObj = res.data.data
						this.$nextTick(() => {
							this.setEliteEcharts()
						})
					}
				})
		},

		ccSelectChange() {
			this.$nextTick(() => {
				this.setCxlqLineEcharts(this.cxlqData.chart_data)
			})
		},

		wgjSelectChange() {
			this.$nextTick(() => {
				this.setWgjLineEcharts(this.wgjData.chart_data)
			})
		},

		zzSelectChange() {
			this.$nextTick(() => {
				this.setzzLineEcharts(this.zzDataObj.chart_data)
			})
		},

		getStaffByStationList() {
			this.$axios
				.post(
					'/p/Staff/getStaffByStation',
					this.$qs({
						department_uuid: this.deparmentId.toString(''),
						type: 'staff',
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						// console.log(res.data.data)
						this.stationList = res.data.data
						this.getData()
					}
				})
		},

		stationChange() {
			// if (!this.stationUuid.length) {
			// 	this.stationUuid = []
			// 	return false
			// }
			// this.stationUuid.forEach(item => {
			// 	if (item == 'quanxuan') {
			// 		var uuids = []
			// 		this.stationList.forEach(itm => {
			// 			uuids.push(itm.uuid)
			// 		})
			// 		this.stationUuid = uuids
			// 	}
			// })
		},

		getGymData() {
			this.$axios.post('/p/Gymware/syncReps').then(res => {
				if (res.data.code == 0) {
				}
			})
		},
		delData() {
			this.$axios.post('/p/Gymware/delete').then(res => {
				if (res.data.code == 0) {
				}
			})
		},
		selectChange() {},
		setEchartsBar(items) {
			var cname = 'GymEchartsBar'
			let myChart = this.$echarts.init(document.getElementById(cname))

			// this.lineData = res.data.data.power_chart
			// 			this.barData = res.data.data.velocity_chart
			var cdata = items.velocity_chart[0].chart_info[0].data,
				x_data = [],
				value_data = []
			for (var i in cdata) {
				x_data.push(i)
				value_data.push(cdata[i])
			}

			var options = {
				title: {
					left: 'center',
					// text: items.chart_info[0].type,
				},
				grid: {
					left: '10%',
					right: '3%',
					top: '10%',
					bottom: '15%',
				},
				xAxis: {
					type: 'category',
					data: x_data,
					axisLine: {
						show: true,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				yAxis: {
					type: 'value',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					// formatter: '{a}: {c}',
					formatter: function(item) {
						return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value
					},
				},
				color: ['#0055e9'],

				series: [
					{
						data: value_data,
						type: 'bar',
						barWidth: 16,
						name: '平均速度(m/s)',
					},
				],
			}
			myChart.setOption(options)
		},
		setEchartsLine(items) {
			var cname = 'GymEchartsLine'

			// this.lineData = res.data.data.power_chart
			// 			this.barData = res.data.data.velocity_chart
			let myChart = this.$echarts.init(document.getElementById(cname))
			var cdata = items.power_chart[0].chart_info[0].data,
				x_data = [],
				value_data = []
			for (var i in cdata) {
				x_data.push(i)
				value_data.push(cdata[i])
			}
			var options = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					formatter: function(item) {
						return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value
					},
				},
				grid: {
					left: '15%',
					right: '3%',
					top: '20%',
					bottom: '15%',
				},
				xAxis: {
					type: 'category',
					data: x_data,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: false,
					},
				},
				yAxis: {
					type: 'value',
					// interval: 1,
					min: 'dataMin',
					// interval: 2,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
						// interval: 2,
					},
				},
				color: ['#0055e9'],
				series: [
					{
						name: '平均功率(W)',
						data: value_data,
						type: 'line',
						// symbol: 'circle',
						// symbolSize: 8,
						// borderWidth: 5,
						itemStyle: {
							borderColor: '#0055e9',
						},

						symbolSize: 10,
						symbol: 'circle',
						smooth: true,
						lineStyle: {
							// width: 3,
							// shadowColor: 'rgba(0,0,0,0.3)',
							// shadowBlur: 10,
							// shadowOffsetY: 8,
						},
					},
				],
			}
			myChart.setOption(options)
		},
		deviceChange() {
			this.deviceList.forEach(itm => {
				if (itm.uuid == this.deviceUuid) {
					this.deviceName = itm.name
				}
			})
			this.getData()
		},
		setGymEcharts(rows) {
			this.$nextTick(() => {
				this.setEchartsBar(rows)
				this.setEchartsLine(rows)
			})
		},

		// 测力台
		setCltLineEcharts(datas) {
			var glData = [],
				sdData = [],
				llData = [],
				xData = []
			datas.chart_data.forEach(item => {
				glData.push(item.power)
				sdData.push(item.speed)
				llData.push(item.forces)
				xData.push(item.time_index)
			})
			let myChart = this.$echarts.init(document.getElementById('cltLineEcharts'))

			var options = {
				tooltip: {
					trigger: 'axis',
				},
				legend: {
					// data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
				},
				grid: {
					left: '3%',
					right: '10%',
					bottom: '15%',
					containLabel: true,
				},
				xAxis: {
					name: '时间m/s',
					type: 'category',
					boundaryGap: false,
					data: xData,
				},
				yAxis: {
					type: 'value',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
				},
				dataZoom: [
					{
						show: true,
						realtime: true,
						start: 0,
						end: 10,
						// xAxisIndex: [0, 1],
					},
					{
						type: 'inside',
						realtime: true,
						start: 0,
						end: 10,
						// xAxisIndex: [0, 1],
					},
				],
				color: ['rgb(46, 126, 247)', 'rgb(128, 235, 56)', 'rgb(255, 202, 55)'],
				series: [
					{
						name: '功率(W)',
						type: 'line',
						stack: 'Total',
						data: glData,
					},
					{
						name: '速度(m/s)',
						type: 'line',
						stack: 'Total',
						data: sdData,
					},
					{
						name: '力量(N)',
						type: 'line',
						stack: 'Total',
						data: llData,
					},
				],
			}

			myChart.setOption(options)
		},

		// CMJ
		setCMJLineEcharts(datas) {
			var leftData= datas.left_arr,
			rightData = datas.right_arr,
				xData = datas.time_arr
			let myChart = this.$echarts.init(document.getElementById('cmjLineEcharts'))

			var options = {
				tooltip: {
					trigger: 'axis',
				},
				legend: {
					// data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
				},
				grid: {
					left: '3%',
					right: '10%',
					bottom: '15%',
					containLabel: true,
				},
				xAxis: {
					name: '时间(秒)',
					type: 'category',
					boundaryGap: false,
					data: xData,
				},
				yAxis: {
					name: '力量(牛)',
					type: 'value',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
				},
				dataZoom: [
					{
						show: true,
						realtime: true,
						start: 0,
						end: 10,
						// xAxisIndex: [0, 1],
					},
					{
						type: 'inside',
						realtime: true,
						start: 0,
						end: 10,
						// xAxisIndex: [0, 1],
					},
				],
				color: ['rgb(46, 126, 247)', 'rgb(128, 235, 56)', 'rgb(255, 202, 55)'],
				series: [
					{
						name: '左侧力量',
						type: 'line',
						stack: 'Total',
						data: leftData,
					},
					{
						name: '右侧力量',
						type: 'line',
						stack: 'Total',
						data: rightData,
					},
				],
			}

			myChart.setOption(options)
		},
		// CMJ 柱状图
		setCMJBarEcharts(name, cdata) {
			let myChart = this.$echarts.init(document.getElementById(name))

			var x_data = []
			for(var i = 1;i<=cdata.rows.length; i ++) {
				x_data.push(i)
			}

			var options = {
				title: {
					left: 'center',
					// text: items.chart_info[0].type,
				},
				grid: {
					left: '10%',
					right: '3%',
					top: '10%',
					bottom: '15%',
				},
				xAxis: {
					type: 'category',
					data: x_data,
					axisLine: {
						show: true,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				yAxis: {
					type: 'value',
					name: cdata.unit_name,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					// formatter: '{a}: {c}',
					formatter: function(item) {
						return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value
					},
				},
				color: ['#0055e9'],

				series: [
					{
						data: cdata.rows,
						type: 'bar',
						barWidth: 16,
						name: cdata.name,
					},
				],
			}
			myChart.setOption(options)
		},

		// 冲刺训练器
		setCxlqLineEcharts(datas) {
			var xData = [],
				yData = []

			datas.forEach(item => {
				if (this.ccFirstValue == 1) {
					xData.push(item.time_index)
				} else if (this.ccFirstValue == 2) {
					xData.push(item.position_index)
				}
				if (this.ccLastValue == 1) {
					yData.push(item.power)
				} else if (this.ccLastValue == 2) {
					yData.push(item.speed)
				} else if (this.ccLastValue == 3) {
					yData.push(item.forces)
				} else if (this.ccLastValue == 4) {
					yData.push(item.acceleration)
				}
			})

			let myChart = this.$echarts.init(document.getElementById('cxlqLineEcharts'))

			var options = {
				tooltip: {
					trigger: 'axis',
				},
				// legend: {
				// data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
				// },
				grid: {
					left: '3%',
					right: '10%',
					bottom: '15%',
					containLabel: true,
				},
				xAxis: {
					name: this.ccFirstValue == 1 ? '时间 m/s' : '位置 /m',
					type: 'category',
					boundaryGap: false,
					data: xData,
				},
				yAxis: {
					name: this.ccLastValue == 1 ? '功率 /W' : this.ccLastValue == 2 ? '速度 m/s' : this.ccLastValue == 3 ? '力量 /kg' : this.ccLastValue == 4 ? '加速度 m/s' : '',
					type: 'value',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
				},
				dataZoom: [
					{
						show: true,
						realtime: true,
						start: 0,
						end: 10,
						// xAxisIndex: [0, 1],
					},
					{
						type: 'inside',
						realtime: true,
						start: 0,
						end: 10,
						// xAxisIndex: [0, 1],
					},
				],
				color: ['rgb(46, 126, 247)', 'rgb(128, 235, 56)', 'rgb(255, 202, 55)'],
				series: [
					{
						name: this.ccLastValue == 1 ? '功率 /W' : this.ccLastValue == 2 ? '速度 m/s' : this.ccLastValue == 3 ? '力量 /kg' : this.ccLastValue == 4 ? '加速度 m/s' : '',
						type: 'line',
						stack: 'Total',
						data: yData,
					},
				],
			}

			myChart.setOption(options)
		},

		// 无轨迹功能训练器
		setWgjLineEcharts(datas) {
			var zxData = [],
				fxData = [],
				xData = []
			for (var i = 0; i < datas.length / 2; i++) {
				xData.push(i + 1)
			}
			datas.forEach((item, index) => {
				if (index % 2 == 0) {
					if (this.wgjSelectValue == 1) {
						zxData.push(item.max_power)
					} else if (this.wgjSelectValue == 2) {
						zxData.push(item.avg_power)
					} else if (this.wgjSelectValue == 3) {
						zxData.push(item.max_speed)
					} else if (this.wgjSelectValue == 4) {
						zxData.push(item.avg_speed)
					} else if (this.wgjSelectValue == 5) {
						zxData.push(item.max_rfd)
					} else if (this.wgjSelectValue == 6) {
						zxData.push(item.avg_rfd)
					}
				} else {
					if (this.wgjSelectValue == 1) {
						fxData.push(item.max_power)
					} else if (this.wgjSelectValue == 2) {
						fxData.push(item.avg_power)
					} else if (this.wgjSelectValue == 3) {
						fxData.push(item.max_speed)
					} else if (this.wgjSelectValue == 4) {
						fxData.push(item.avg_speed)
					} else if (this.wgjSelectValue == 5) {
						fxData.push(item.max_rfd)
					} else if (this.wgjSelectValue == 6) {
						fxData.push(item.avg_rfd)
					}
				}
			})
			let myChart = this.$echarts.init(document.getElementById('wgjLineEcharts'))

			var options = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					formatter: function(item) {
						return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value + '</br>' + item[1].marker + ' ' + item[1].seriesName + ': ' + item[1].value
					},
				},
				grid: {
					left: '15%',
					right: '3%',
					top: '20%',
					bottom: '15%',
				},
				xAxis: {
					type: 'category',
					data: xData,
					axisLine: {
						show: true,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				yAxis: {
					name:
						this.wgjSelectValue == 1
							? '峰值功率 /W'
							: this.wgjSelectValue == 2
							? '平均功率 /W'
							: this.wgjSelectValue == 3
							? '峰值速度 m/s'
							: this.wgjSelectValue == 4
							? '平均速度 m/s'
							: this.wgjSelectValue == 5
							? '峰值Rfd'
							: this.wgjSelectValue == 6
							? '平均Rfd'
							: '',
					type: 'value',
					// interval: 1,
					min: 'dataMin',
					// interval: 2,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
						// interval: 2,
					},
				},
				color: ['rgb(46, 126, 247)', 'rgb(128, 235, 56)'],
				series: [
					{
						name: '平均功率(W)',
						data: zxData,
						type: 'bar',
						z: '1',
						color: 'rgb(46, 126, 247)',
					},
					{
						name: '平均功率(W)',
						data: fxData,
						type: 'bar',
						z: '1',
						barGap: '-100%',
						color: 'rgb(128, 235, 56)',
					},
				],
			}

			myChart.setOption(options)
		},

		// 无轨迹功能训练器
		setzzLineEcharts(datas) {
			var zxData1 = [],
				zxData2 = [],
				fxData1 = [],
				fxData2 = [],
				xData = []
			for (var i = 0; i < datas.length / 4; i++) {
				xData.push(i + 1)
			}
			datas.forEach((item, index) => {
				if (index % 4 == 0) {
					if (this.wgjSelectValue == 1) {
						zxData1.push(item.max_power)
					} else if (this.wgjSelectValue == 2) {
						zxData1.push(item.avg_power)
					} else if (this.wgjSelectValue == 3) {
						zxData1.push(item.max_speed)
					} else if (this.wgjSelectValue == 4) {
						zxData1.push(item.avg_speed)
					} else if (this.wgjSelectValue == 5) {
						zxData1.push(item.max_rfd)
					} else if (this.wgjSelectValue == 6) {
						zxData1.push(item.avg_rfd)
					}
				} else if (index % 4 == 1) {
					if (this.wgjSelectValue == 1) {
						zxData2.push(item.max_power)
					} else if (this.wgjSelectValue == 2) {
						zxData2.push(item.avg_power)
					} else if (this.wgjSelectValue == 3) {
						zxData2.push(item.max_speed)
					} else if (this.wgjSelectValue == 4) {
						zxData2.push(item.avg_speed)
					} else if (this.wgjSelectValue == 5) {
						zxData2.push(item.max_rfd)
					} else if (this.wgjSelectValue == 6) {
						zxData2.push(item.avg_rfd)
					}
				} else if (index % 4 == 2) {
					if (this.wgjSelectValue == 1) {
						fxData1.push(item.max_power)
					} else if (this.wgjSelectValue == 2) {
						fxData1.push(item.avg_power)
					} else if (this.wgjSelectValue == 3) {
						fxData1.push(item.max_speed)
					} else if (this.wgjSelectValue == 4) {
						fxData1.push(item.avg_speed)
					} else if (this.wgjSelectValue == 5) {
						fxData1.push(item.max_rfd)
					} else if (this.wgjSelectValue == 6) {
						fxData1.push(item.avg_rfd)
					}
				} else if (index % 4 == 3) {
					if (this.wgjSelectValue == 1) {
						fxData2.push(item.max_power)
					} else if (this.wgjSelectValue == 2) {
						fxData2.push(item.avg_power)
					} else if (this.wgjSelectValue == 3) {
						fxData2.push(item.max_speed)
					} else if (this.wgjSelectValue == 4) {
						fxData2.push(item.avg_speed)
					} else if (this.wgjSelectValue == 5) {
						fxData2.push(item.max_rfd)
					} else if (this.wgjSelectValue == 6) {
						fxData2.push(item.avg_rfd)
					}
				}
			})
			let myChart = this.$echarts.init(document.getElementById('zzLineEcharts'))

			var options = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					formatter: function(item) {
						return (
							item[0].marker +
							' ' +
							item[0].seriesName +
							': ' +
							item[0].value +
							'</br>' +
							item[1].marker +
							' ' +
							item[1].seriesName +
							': ' +
							item[1].value +
							'</br>' +
							item[2].marker +
							' ' +
							item[2].seriesName +
							': ' +
							item[2].value +
							'</br>' +
							item[3].marker +
							' ' +
							item[3].seriesName +
							': ' +
							item[3].value
						)
					},
				},
				grid: {
					left: '15%',
					right: '3%',
					top: '20%',
					bottom: '15%',
				},
				dataZoom: [
					{
						show: true,
						height: 12,
						xAxisIndex: [0],
						bottom: '2%',
						start: 1,
						end: 10,
						handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
						handleSize: '110%',
						handleStyle: {
							color: '#d3dee5',
						},
						textStyle: {
							color: '#fff',
						},
						borderColor: '#90979c',
					},
					{
						type: 'inside',
						show: true,
						height: 20,
						start: 1,
						end: 35,
					},
				],
				xAxis: {
					type: 'category',
					data: xData,
					axisLine: {
						show: true,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				yAxis: {
					name:
						this.wgjSelectValue == 1
							? '峰值功率 /W'
							: this.wgjSelectValue == 2
							? '平均功率 /W'
							: this.wgjSelectValue == 3
							? '峰值速度 m/s'
							: this.wgjSelectValue == 4
							? '平均速度 m/s'
							: this.wgjSelectValue == 5
							? '峰值Rfd'
							: this.wgjSelectValue == 6
							? '平均Rfd'
							: '',
					type: 'value',
					// interval: 1,
					min: 'dataMin',
					// interval: 2,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
						// interval: 2,
					},
				},
				color: ['rgb(46, 126, 247)', 'rgb(53, 200, 203)'],
				series: [
					{
						name: '左平均功率(W)',
						data: zxData1,
						type: 'bar',
						barWidth: 12,
						barCategoryGap: 10,
						barGap: '30%',
						barGap: '30%',
						z: 1,
						stack: '1',
						stackStrategy: 'samesign',
						color: 'rgb(46, 126, 247)',
					},
					{
						name: '右平均功率(W)',
						data: zxData2,
						type: 'bar',
						barWidth: 12,
						barCategoryGap: 10,
						barGap: '30%',
						stack: '2',
						barGap: '30%',
						z: 1,
						color: 'rgb(46, 126, 247)',
					},
					{
						name: '左平均功率(W)',
						data: fxData1,
						type: 'bar',
						barWidth: 12,
						barCategoryGap: 10,
						barGap: '30%',
						stack: '1',
						barGap: '30%',
						color: 'rgb(53, 200, 203)',
					},
					{
						name: '右平均功率(W)',
						data: fxData2,
						type: 'bar',
						barWidth: 12,
						barCategoryGap: 10,
						barGap: '30%',
						stack: '2',
						barGap: '30%',
						color: 'rgb(53, 200, 203)',
					},
				],
			}

			myChart.setOption(options)
		},

		// 腾空时间图表
		setTksjEcharts(c_data) {
			var cname = 'tksjEcharts'
			let myChart = this.$echarts.init(document.getElementById(cname))

			var x_data = []
			c_data.forEach((item, index) => {
				x_data.push(index + 1)
			})

			var options = {
				title: {
					left: 'center',
					// text: items.chart_info[0].type,
				},
				grid: {
					left: '10%',
					right: '3%',
					top: '10%',
					bottom: '15%',
				},
				xAxis: {
					type: 'category',
					data: x_data,
					axisLine: {
						show: true,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				yAxis: {
					type: 'value',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					// formatter: '{a}: {c}',
					formatter: function(item) {
						return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value
					},
				},
				color: ['#0055e9'],

				series: [
					{
						data: c_data,
						type: 'bar',
						barWidth: 16,
						name: '腾空时间',
					},
				],
			}
			myChart.setOption(options)
		},

		// 跳跃点图表
		setTydEcharts(c_data) {
			var cname = 'tydEcharts'
			let myChart = this.$echarts.init(document.getElementById(cname))

			var x_data = []
			c_data.forEach((itm, index) => {
				x_data.push(index + 1)
			})

			var options = {
				title: {
					left: 'center',
					// text: items.chart_info[0].type,
				},
				grid: {
					left: '10%',
					right: '3%',
					top: '10%',
					bottom: '15%',
				},
				xAxis: {
					type: 'category',
					data: x_data,
					axisLine: {
						show: true,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				yAxis: {
					type: 'value',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					// formatter: '{a}: {c}',
					formatter: function(item) {
						return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value
					},
				},
				color: ['#0055e9'],

				series: [
					{
						data: c_data,
						type: 'bar',
						barWidth: 16,
						name: '高度',
					},
				],
			}
			myChart.setOption(options)
		},

		//请求列表数据
		getData() {
			this.$axios
				.post(
					'/p/equipment_record/phyLists',
					this.$qs({
						page: this.page,
						page_size: this.page_size,
						cate_uuid: this.deviceUuid,
						name: this.input4,
						start_date: this.dateTime ? this.dateTime[0] : '',
						end_date: this.dateTime ? this.dateTime[1] : '',
						department_uuid_str: this.deparmentId.toString(''),
						staff_uuid_str: this.stationUuid.toString(''),
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.tableList = res.data.data.rows
						this.total = res.data.data.total
						this.tableLoading = false
					}
				})
		},
		// 请求gymware数据
		getGymwareData() {
			this.$axios
				.post(
					'/p/Gymware/info',
					this.$qs({
						athleteReference: this.personUuid,
						date: this.searchDate,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.GymwareList = res.data.data.rows
						this.lineData = res.data.data.power_chart
						this.barData = res.data.data.velocity_chart
						this.$nextTick(() => {
							this.lineData.forEach((item, index) => {
								this.setEchartsLine(index, item)
							})
							this.barData.forEach((item, index) => {
								this.setEchartsBar(index, item)
							})
						})
					}
				})
		},

		// 划船机图表
		setHcjEcharts(name) {
			let myChart = this.$echarts.init(document.getElementById(name))
			var xData = []
			for(var i = 1; i<=this.hcjEchartsObject[name].list.length; i++) {
				xData.push(i)
			}
			var options = {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '3%',
					right: '5%',
					bottom: '10%',
					top: '10%',
					containLabel: true,
				},
				xAxis: {
					name: '',
					type: 'category',
					boundaryGap: false,
					axisLine: {
						show: false,
					},
					axisLabel: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					data: xData,
				},
				yAxis: {
					name: '',
					type: 'value',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						lineStyle: {
							type: 'dashed',
							color: '#e1e1e1'
						}
					}
				},
				color: ['#9DC6FC'],
				series: [
					{
						name: '',
						type: 'line',
						stack: 'Total',
						areaStyle: {},
						symbol: 'none',
						data: this.hcjEchartsObject[name].list,
					},
				],
			}

			myChart.setOption(options)
		},

		setWattEchartsLine(items) {
			let myChart = this.$echarts.init(document.getElementById('wattEchartsLine'))
			
			var cdata = [],
				x_data = items.record_time_list,
				value_data = items.power_list,
				hart_data = [],
				work_data = items.cadence_list
		
			var options = {
				title: {
					text: '功率/心率/踏频',
					// top: 10,
					left: 'center'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					// formatter: function(item) {
					// 	return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value
					// },
				},
				dataZoom: [
					{
						show: true,
						realtime: true,
						start: 0,
						end: 10,
						// xAxisIndex: [0, 1],
					},
					{
						type: 'inside',
						realtime: true,
						start: 0,
						end: 10,
						// xAxisIndex: [0, 1],
					},
				],
				legend: {
		        show: true,
						top: 40,
		    //     icon: "circle",
		    //     bottom: 30,
		    //     center: 0,
		    //     itemWidth: 14,
		    //     itemHeight: 14,
		    //     itemGap: 21,
		    //     orient: "horizontal",
		    //     data: ['a', 'b'],
		    //     textStyle: {
		    //         fontSize: '70%',
		    //         color: '#8C8C8C'
		    //     },
		    },
				grid: {
					left: '10%',
					right: '10%',
					top: '25%',
					bottom: '20%',
				},
				xAxis: {
					type: 'category',
					data: x_data,
					axisLine: {
						lineStyle: {
						}
					},
					axisTick: {
						alignWithLabel: true,
						boundaryGap: true,
					},
					// axisLabel: {
					// 	show: false,
					// },
				},
				yAxis: [{
					name: '功率',
					type: 'value',
					// interval: 1,
					min: 'dataMin',
					// interval: 2,
					axisLine: {
						lineStyle: {
							type: 'dashed'
						}
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
						lineStyle: {
							color: '#000'
						}
						// interval: 2,
					},
				},
				{
					name: '心率/踏频',
					type: 'value',
					axisLine: {
						show: false,
					},
					position: 'right',
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
						textStyle: {
							color: '#000'
						}
						// interval: 2,
					},
				}],
				color: ['#0055e9', '#ff050d', '#fabd0c'],
				series: [
					{
						name: '平均功率(W)',
						data: value_data,
						type: 'line',
						itemStyle: {
							borderColor: '#0055e9',
						},
						symbolSize: 0,
						symbol: 'circle',
						smooth: true,
					},
					{
						name: '心率',
						data: hart_data,
						type: 'line',
						yAxisIndex: 1,
						alignTicks: true,
						itemStyle: {
							borderColor: '#ff050d',
						},
						symbolSize: 0,
						symbol: 'circle',
						smooth: true,
					},
					{
						name: '踏频',
						data: work_data,
						type: 'line',
						yAxisIndex: 1,
						alignTicks: true,
						itemStyle: {
							borderColor: '#fabd0c',
						},
						symbolSize: 0,
						symbol: 'circle',
						smooth: true,
					},
				],
			}
			myChart.setOption(options)
		},

		setEliteEcharts() {
			let myChart = this.$echarts.init(document.getElementById('elite_echarts'))
			var options = {}
			if(this.activeName == 'first') {
				var y_data = [], x_data = [], avg_data = []
				this.eliteDataObj.table_info[0].test_data.forEach(item => {
					y_data.push(item.peak)
					x_data.push(item.num)
					avg_data.push(item.average)
				})
				options = {
					backgroundColor: '#fff',
					title: {
						text: "",
						top: 10,
						left: 15,
						textStyle: {
							color: "#35598d",
							fontSize: 16,
							fontWeight: 'normal'
						}
					},
					tooltip: {
						trigger: 'axis',
					},
					grid: {
						left: '10%',
						right: '15%',
						bottom: '5%',
						top: '20%',
						containLabel: true
					},
					legend: {
						icon: 'rect',
						// right: "3%",
						top: 13,
						itemWidth: 20,
						itemHeight: 10,
						textStyle: {
							color: '#555'
						},
					},
					xAxis: {
						name: '次数',
						type: 'category',
						data: x_data,
						axisLabel: { //坐标轴字体颜色
							textStyle: {
								color: '#000'
							}
						},
						axisLine: {
							show: true,
						},
						axisTick: { //y轴刻度线
							show: false
						},
						splitLine: { //网格
							show: false,
						}
					},
					yAxis: {
						name: '速度（米/秒）',
						type: 'value',
						axisLabel: { //坐标轴字体颜色
							textStyle: {
								color: '#000'
							}
						},
						axisLine: {
							show: true,
							textStyle: {
								color: '#9eaaba'
							}
						},
						axisTick: { //y轴刻度线
							show: false
						},
						splitLine: { //网格
							show: true,
							lineStyle: {
								color: '#dadde4',
								type: "dashed"
							}
						}
					},
					series: [{
						name: '峰值',
						type: 'bar',
						stack: '策略变更',
						barWidth: '20', //柱子宽度
						itemStyle: { //柱子颜色
							normal: {
								color: '#2dafeb',
								// borderColor: "#fff",
								borderWidth: 10,
							}
						},
						data: y_data
					}, {
						name: '平均值',
						type: 'bar',
						stack: '策略变更',
						barWidth: '20', //柱子宽度
						itemStyle: { //柱子颜色
							normal: {
								color: '#35c68d',
								// borderColor: "#fff",
								borderWidth: 10,
							}
						},
						data: avg_data
					}]
				};
			} else if (this.activeName == 'second') {
				var y_data = [], x_data = [], avg_data = []
				this.eliteDataObj.table_info[1].test_data.forEach(item => {
					y_data.push(item.peak)
					x_data.push(item.num)
					avg_data.push(item.average)
				})
				options = {
					backgroundColor: '#fff',
					title: {
						text: "",
						top: 10,
						left: 15,
						textStyle: {
							color: "#35598d",
							fontSize: 16,
							fontWeight: 'normal'
						}
					},
					tooltip: {
						trigger: 'axis',
					},
					grid: {
						left: '10%',
						right: '15%',
						bottom: '5%',
						top: '20%',
						containLabel: true
					},
					legend: {
						icon: 'rect',
						// right: "3%",
						top: 13,
						itemWidth: 20,
						itemHeight: 10,
						textStyle: {
							color: '#555'
						},
					},
					xAxis: {
						name: '次数',
						type: 'category',
						data: x_data,
						axisLabel: { //坐标轴字体颜色
							textStyle: {
								color: '#000'
							}
						},
						axisLine: {
							show: true,
						},
						axisTick: { //y轴刻度线
							show: false
						},
						splitLine: { //网格
							show: false,
						}
					},
					yAxis: {
						// name: '速度（米/秒）',
						name: '功率（瓦）',
						type: 'value',
						axisLabel: { //坐标轴字体颜色
							textStyle: {
								color: '#000'
							}
						},
						axisLine: {
							show: true,
							textStyle: {
								color: '#9eaaba'
							}
						},
						axisTick: { //y轴刻度线
							show: false
						},
						splitLine: { //网格
							show: true,
							lineStyle: {
								color: '#dadde4',
								type: "dashed"
							}
						}
					},
					series: [{
						name: '峰值',
						type: 'bar',
						stack: '策略变更',
						barWidth: '20', //柱子宽度
						itemStyle: { //柱子颜色
							normal: {
								color: '#2dafeb',
								// borderColor: "#fff",
								borderWidth: 10,
							}
						},
						data: y_data
					}, {
						name: '平均值',
						type: 'bar',
						stack: '策略变更',
						barWidth: '20', //柱子宽度
						itemStyle: { //柱子颜色
							normal: {
								color: '#35c68d',
								// borderColor: "#fff",
								borderWidth: 10,
							}
						},
						data: avg_data
					}]
				};
			} else if (this.activeName == 'third') {
				var y_data = [], x_data = []
				this.eliteDataObj.table_info[2].test_data.forEach(item => {
					y_data.push(item.peak)
					x_data.push(item.num)
				})
				options = {
					backgroundColor: '#fff',
					title: {
						text: "",
						top: 10,
						left: 15,
						textStyle: {
							color: "#35598d",
							fontSize: 16,
							fontWeight: 'normal'
						}
					},
					tooltip: {
						trigger: 'axis',
					},
					grid: {
						left: '10%',
						right: '15%',
						bottom: '5%',
						top: '20%',
						containLabel: true
					},
					legend: {
						icon: 'rect',
						// right: "3%",
						top: 13,
						itemWidth: 20,
						itemHeight: 10,
						textStyle: {
							color: '#555'
						},
					},
					xAxis: {
						name: '次数',
						type: 'category',
						data: x_data,
						axisLabel: { //坐标轴字体颜色
							textStyle: {
								color: '#000'
							}
						},
						axisLine: {
							show: true,
						},
						axisTick: { //y轴刻度线
							show: false
						},
						splitLine: { //网格
							show: false,
						}
					},
					yAxis: {
						// name: '速度（米/秒）',
						name: '做功（焦耳）',
						type: 'value',
						axisLabel: { //坐标轴字体颜色
							textStyle: {
								color: '#000'
							}
						},
						axisLine: {
							show: true,
							textStyle: {
								color: '#9eaaba'
							}
						},
						axisTick: { //y轴刻度线
							show: false
						},
						splitLine: { //网格
							show: true,
							lineStyle: {
								color: '#dadde4',
								type: "dashed"
							}
						}
					},
					series: [{
						name: '做功值',
						type: 'bar',
						stack: '策略变更',
						barWidth: '20', //柱子宽度
						itemStyle: { //柱子颜色
							normal: {
								color: '#2dafeb',
								// borderColor: "#fff",
								borderWidth: 10,
							}
						},
						data: y_data
					}]
				};
			}
			myChart.setOption(options)
		},

		handleCurrentChange(val) {
			this.page = val
			this.getData()
		},

		getTime(value) {
			const date = new Date(value * 1000)
			const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
			const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
			// console.log(hours, minutes)
			return hours + ':' + minutes
		},
	},
}
</script>
<style lang="scss" scoped>
.child-top {
	margin-top: 10px;
	/deep/ .el-date-editor {
		border-bottom: 2px solid #ccc;
	}
}
.disColor {
	color: #ccc !important;
}

.text {
	display: inline-block;
	margin-left: 20px;
}

/deep/.el-dialog__title {
	color: #303133;
	font-weight: 700;
}

/deep/.el-input {
	position: relative;
	font-size: 0.72917rem;
	display: inline-block;
	// width: 222px;
}

/deep/.el-table th > .cell {
	padding-left: 15px;
}
/deep/.el-pagination__editor.el-input {
	width: 50px;
}

.child-top {
	.input-from {
		.searchBtn {
			margin-top: 10px;
		}
	}
	.operation-button {
		// float: left;
		margin-bottom: 10px;
		margin-top: 10px;
	}
}
.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}

.el-pagination {
	padding-bottom: 10px;
}

.echarts_view {
	overflow-x: auto;
	box-sizing: border-box;
	overflow-y: hidden;
	margin-bottom: 20px;
	text-align: center;
	display: flex;
}

.echarts_content {
	flex: 1;
	height: 300px;
	white-space: nowrap;
	box-sizing: border-box;
	// margin-bottom: 1px;
	div {
		display: inline-block;
		background: #fff;
		margin-right: 10px;
		width: 400px;
		height: 300px;
		box-sizing: border-box;
	}
	.echarts_title {
		width: auto;
		padding: 0 20px;
		text-align: center;
		// line-height: 300px;
		// height: 20px;
		// transform: rotate(-90deg);
		writing-mode: vertical-lr;
		margin-right: 0;
	}
}

.table_view {
	margin-bottom: 20px;
}

.gymaware_view {
	/deep/ .el-dialog__body {
		height: 600px;
		overflow-y: auto;
	}
}

.clbDialog_view {
	.clt_view {
		height: 500px;
		overflow-y: auto;
		overflow-x: hidden;
	}
}

.ekfDialog_view {
	/deep/ .el-dialog__body {
		// height: 200px;
		overflow-y: auto;
	}
	.ekf_view {
		border: 1px solid rgb(53, 200, 203);
		border-radius: 10px;
		display: flex;
		height: 50px;
		overflow: hidden;
		span {
			width: 100px;
			background: rgb(53, 200, 203);
			text-align: center;
			border-right: 1px solid rgb(53, 200, 203);
			line-height: 50px;
			color: #fff;
		}
		p {
			line-height: 50px;
			font-size: 16px;
			color: #000;
			padding-left: 20px;
		}
	}

	.clb_view {
		p {
			margin: 10px auto;
			line-height: 30px;
			font-size: 16px;
			border: 1px solid rgb(53, 200, 203);
			border-radius: 10px;
			overflow: hidden;
			color: #000;

			span {
				display: inline-block;
				width: 150px;
				background: rgb(53, 200, 203);
				text-align: center;
				line-height: 50px;
				color: #fff;
				margin-right: 20px;
			}
		}
	}

	.el-form {
		margin-bottom: 0;
	}

	.zbsj {
		margin-top: 20px;
	}
	.clt_view {
		position: relative;
		.top_title {
			display: flex;
			align-items: center;
			font-size: 18px;
			color: #333;
			font-weight: 500;
			font-family: PingFangSC-Medium, PingFang SC;
			span {
				display: inline-block;
				width: 6px;
				height: 20px;
				background: #0055e9;
				border-radius: 3px;
				margin-right: 10px;
			}
		}

		.clt_info_view {
			display: flex;
			flex-wrap: wrap;
			margin: 10px 0;
			> div {
				width: 50%;
				line-height: 40px;
				font-size: 16px;
				color: #333;
				span {
					display: inline-block;
					width: 70px;
				}
			}
		}

		.zb_echarts_view {
			h2 {
				margin-top: 20px;
				background: #5895ff;
				font-weight: 400;
				color: #fff;
				line-height: 40px;
				padding-left: 10px;
			}
		}

		.div-flex {
			display: flex;
			.el-form-item {
				flex: 1;
				margin-bottom: 0px;
			}
		}

		.clbEchartsView {
			width: 100%;
			height: 380px;
		}

		#cltLineEcharts {
			width: 100%;
			height: 380px;
		}

		#cmjLineEcharts {
			width: 100%;
			height: 380px;
			margin-bottom: 20px;
		}

		#cxlqLineEcharts {
			width: 100%;
			height: 330px;
		}

		#wgjLineEcharts {
			width: 100%;
			height: 350px;
		}

		#zzLineEcharts {
			width: 100%;
			height: 350px;
		}

		.clt_view_select {
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 9;
			.el-select {
				margin-left: 20px;
			}
		}

		#tksjEcharts,
		#tydEcharts {
			width: 100%;
			height: 350px;
		}
	}

	.hcj_view {
		height: 600px;
		overflow-y: auto;
		ul {
			display: flex;
			margin-bottom: 20px;
			li {
				flex: 1;
				border: 1px solid #E3E3E3;
				margin-right: 20px;
				height: 55px;
				display: flex;
				align-items: center;
				font-weight: 400;
				color: #666666;
				// justify-content: center;
				span {
					width: 80px;
					height: 55px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: #EDEDED;
				}
				p {
					flex: 1;
					text-align: center;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.top_date_view {
			display: flex;
			justify-content: space-around;
			.date_view {
				// width: 150px;
				// height: 50px;
				line-height:50px;
				// text-align: center;
				// background: #F4F6F9;
				// border: 1px solid #E3E3E3;
				margin-bottom: 20px;
				font-weight: 400;
				font-size: 16px;
				color: #000;
				// margin-left: 200px;
				&:first-child {
					margin-left: 0;
				}
			}
		}

		.hcj_echarts_view {
			width: 49%;
			// height: 450px;
			border: 1px solid #E3E3E3;
			float: left;
			margin-bottom: 20px;
			overflow-x: hidden;
			p {
				text-align: center;
				font-size: 20px;
				color: #333333;
				margin-top: 30px;
			}
			.value_view {
				width: 500px;
				border: 1px solid #E3E3E3;
				margin: 20px auto 0;
				height: 100px;

				.value_view_title {
					display: flex;
					background: #F4F6F9;

					div {
						flex: 1;
						text-align: center;
						line-height: 50px;
					}
				}
				.value_view_bot {
					display: flex;
					div {
						flex: 1;
						text-align: center;
						line-height: 50px;
					}
				}
			}
			.hcj_echarts {
				width: 100%;
				height: 251px;
			}
			&:nth-child(even) {
				float: right;
			}
		}
	}

	.watt_view {
		height: 600px;
		.date_view {
			width: 150px;
			height: 50px;
			line-height:50px;
			text-align: center;
			background: #F4F6F9;
			border: 1px solid #E3E3E3;
			margin-bottom: 20px;
			font-weight: 400;
			color: #666666;
			margin-top: 10px;
		}
		.clt_info_view {
			> div {
				width: 25%;
				span {
					text-align: right;
					width: 220px;
				}
			}
		}
		#wattEchartsLine {
			width: 100%;
			height: 350px;
		}
		.bot_view {
			display: flex;
			dl {
				flex: 1;
				margin-top: 10px;
				text-align: center;
				dd{
					font-size: 16px;
					margin-bottom: 5px;
				}
				dt {
					i {
						font-size: 20px;
						// float: left;
					}
				}
			}
		}
	}

	.elite_view {
		.interTop_left {
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #333;
			margin-bottom: 20px;
			.el-avatar {
				width: 60px;
				height: 60px;
				img {
					width: 100%;
				}
			}
			span {
				margin-left: 5px;
				margin-right: 20px;
				font-size: 16px;
			}
		}

		.top_title {
			display: flex;
			align-items: center;
			font-size: 18px;
			color: #333;
			font-weight: 500;
			font-family: PingFangSC-Medium, PingFang SC;
			span {
				display: inline-block;
				width: 6px;
				height: 20px;
				background: #0055e9;
				border-radius: 3px;
				margin-right: 10px;
			}
		}

		ul {
			display: flex;
			margin: 20px 0;
			li {
				width: 25%;
				font-size: 16px;
				span {
					font-size: 16px;
					color: #333;
				}
			}
		}

		.bot_view {
			display: flex;
			height: 300px;
			.bot_view_left {
				flex: 1
			}
			.bot_view_right {
				flex: 1;
				overflow: hidden;
				.el-table {
					width: 100%;
				}
			}
		}

		.bot_info {
			width: 50%;
			height: 90px;
			div {
				// text-align: center;
				padding-left: 30%;
				line-height: 30px;
				font-size: 17px;
				color: #000;
				span {
					width: 200px;
					text-align: right;
					display: inline-block;
				}
			}
		}
	}
}

.detailDialog_view {
	.clt_view {
		height: 500px;
	}
}
</style>
